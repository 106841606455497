import React from 'react';
import Helmet from 'react-helmet';

import CharlesMeta from 'components/common/meta/CharlesMeta';
import Segment from 'utils/segment'
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import Start from 'components/Start/NewStartGeneral';
import 'components/Start/styles.sass'

const StartPage = () => (
  <>
    <Helmet defer={false}>
      <meta name="robots" content="noindex" />
    </Helmet>
    <CharlesMeta
      data={{
        title:
          'Consultation en ligne pour vos problèmes de sexualité, sommeil ou digestion.',
        description:
          'Commencez maintenant votre téléconsultation avec un médecin spécialiste en sexologie, des troubles digestifs, du sommeil ou de la chute de cheveux. Obtenez une ordonnance en 24h. Faites vous livrer vos traitements à domicile en 48h.',
      }}
      segmentCategory={Segment.CATEGORIES.START}
    />
    <StructuredWebPage
      title={'Nos spécialistes sont à votre écoute'}
      description={'Commencez maintenant votre téléconsultation avec un médecin spécialiste en sexologie, des troubles digestifs, du sommeil ou de la chute de cheveux. Obtenez une ordonnance en 24h. Faites vous livrer vos traitements à domicile en 48h.'}
      showAggregateRating={false}
    />
    <Start />
  </>
);

export default StartPage;
