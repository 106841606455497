import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import { Link } from "gatsby";
import SimpleStepper from "../../SimpleStepper";
import QuestionCircle from "images/icons/start/question-circle.svg";
import Assistance from "images/icons/start/small-assistance.svg";
import Mobile from "images/new-images/mobile-black.svg";
import Enveloppe from "images/new-images/enveloppe-black.svg";
import TriangleMenu from "images/new-images/menu-triangle.svg";
import Logo from "images/new-images/logo-charles.svg";
import "./styles.sass";

const TopContainerStart = () => {
  const [dropDown, setDropDown] = useState(false);
  const handleClick = () => {
    setDropDown(!dropDown);
  };

  return (
    <>
      <Container className="top-container d-flex justify-content-center">
        <div className="top-container-div">
          <Link to="/">
            <Logo className="start-logo" />
          </Link>
          <div className="stepper-start">
            <SimpleStepper className="" />
          </div>
          <div className="d-flex d-md-none" onClick={handleClick}>
            <div className="d-flex align-items-center d-md-none">
              <QuestionCircle height={18} />
              <span className="consulter-aide ml-2">Aide</span>
            </div>
          </div>
          <div className="d-none d-md-flex">
            <button className="start-help-button">
              <a href="tel:0186651733">
                <Assistance />

                <span className="assistance-number">01 86 65 17 33 </span>
                <span className="assistance-gratis">Gratuit</span>
              </a>
            </button>
          </div>
        </div>
      </Container>
      <div className="start-toggle-wrapper">
        <div className={dropDown ? "start-toggle-on" : "start-toggle-off"}>
          <img
            className="start-triangle-menu"
            src={TriangleMenu}
            alt="fleche-pleine-haut-picto"
            title="fleche pleine haut picto"
          />
          <div>
            <div className="d-block h4">Vous avez des questions : </div>
            <div className="d-flex align-items-center">
              {" "}
              <Mobile width={16} />
              <a href="tel:0186651733">
                <span className="start-toggle-span">01 86 65 17 33</span>
              </a>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center">
              {" "}
              <Enveloppe width={16} />
              <a href="mailto:contact@charles.co">
                <span className="start-toggle">contact@charles.co</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TopContainerStart;
